import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/opt/build/repo/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import useDirectives_W0tXrzvhuP from "/opt/build/repo/node_modules/@bootstrap-vue-next/nuxt/dist/runtime/useDirectives.mjs";
import createBootstrap_ywvW1KIeJ9 from "/opt/build/repo/node_modules/@bootstrap-vue-next/nuxt/dist/runtime/createBootstrap.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import api_QQzOodE9HA from "/opt/build/repo/plugins/api.js";
import chunks_client_luDY5T2hxw from "/opt/build/repo/plugins/chunks.client.js";
import matomo_client_hZHcd59ZPU from "/opt/build/repo/plugins/matomo.client.js";
import me_qQpC7QiiZK from "/opt/build/repo/plugins/me.js";
import mitt_client_JdecFmZkyR from "/opt/build/repo/plugins/mitt.client.js";
import pinia_plugin_persist_client_seO4uiRJSm from "/opt/build/repo/plugins/pinia-plugin-persist.client.ts";
import sentry_client_shVUlIjFLk from "/opt/build/repo/plugins/sentry.client.ts";
import something_went_wrong_client_tybeCILci3 from "/opt/build/repo/plugins/something-went-wrong.client.js";
import vue_awesome_vRvOWEW2zD from "/opt/build/repo/plugins/vue-awesome.js";
import vue_leaflet_client_YETBDdCASL from "/opt/build/repo/plugins/vue-leaflet.client.js";
import vue3_observe_visiblity_aBXKAQ1X6e from "/opt/build/repo/plugins/vue3-observe-visiblity.js";
export default [
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  useDirectives_W0tXrzvhuP,
  createBootstrap_ywvW1KIeJ9,
  chunk_reload_client_UciE0i6zes,
  api_QQzOodE9HA,
  chunks_client_luDY5T2hxw,
  matomo_client_hZHcd59ZPU,
  me_qQpC7QiiZK,
  mitt_client_JdecFmZkyR,
  pinia_plugin_persist_client_seO4uiRJSm,
  sentry_client_shVUlIjFLk,
  something_went_wrong_client_tybeCILci3,
  vue_awesome_vRvOWEW2zD,
  vue_leaflet_client_YETBDdCASL,
  vue3_observe_visiblity_aBXKAQ1X6e
]